import environment from "../environments";
import { AvailableFAInfoAndChatNameRequestData } from "../interfaces/AvailableFAInfoAndChatNameRequestData";

export function getAvailableFAInfoAndChatName(accessToken: string, accidentId: string) {

    const body: AvailableFAInfoAndChatNameRequestData = {
        LOC: localStorage.getItem('LOC')!,
        FADL: localStorage.getItem('FADL')!,
        accidentId: accidentId
    }

    return fetch(environment.api.getAvailableFAInfoAndChatName,
        {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`,
            },
            method: "POST",
            body: JSON.stringify(body)
        })
        .then(res => res.json())
        .catch(err => console.error(err))
}

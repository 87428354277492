import { FirstAider } from "../interfaces/FirstAider";

export default async function iterateOverEntities(
  client: any
): Promise<Array<FirstAider>> {
  const entities = client.listEntities();
  let firstAiders: Array<FirstAider> = [];

  for await (const entity of entities) {
    firstAiders.push({
      id: `${entity.partitionKey}${entity.rowKey}`,
      name: entity.name as string,
      surname: entity.surname as string,
      email: entity.email as string,
      phone: entity.phone as string,
      availability: entity.availability as boolean,
      timestamp: new Date(entity.timestamp),
      magicHouse: entity.magicHouse,
    });
  }

  return firstAiders;
}

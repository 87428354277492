import { LogLevel } from "@azure/msal-browser";

export const environment = {
    production: false,
    msal: {
        auth: {
            clientId: "998b6b99-dc25-41ee-adcf-12842239c50c",
            authority: "https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd",
            redirectUri: "https://test.firstaiders.pl.abb.com",
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false, // set to "true" if issues on IE11/Edge
        },
        system: {
            loggerOptions: {
                loggerCallback: (level: LogLevel, message: string, containsPii: any) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            return;
                    }
                }
            }
        }
    },
    api: {
        getAvailableFAInfoAndChatName: "https://prod-225.westeurope.logic.azure.com:443/workflows/ede0928c48d84648956aa411bb3246f1/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun",
        callForHelp: "https://prod-183.westeurope.logic.azure.com:443/workflows/6b56305016d440ef99e8d1f97b914c86/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun",
        cancelCallForHelp: "https://prod-198.westeurope.logic.azure.com:443/workflows/6901354338394424add471558c0f3d42/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun",
        HC: "https://prod-28.westeurope.logic.azure.com:443/workflows/ddf0e71c60754981a972b0aa5235e3ad/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=OGA91e4M0buEfzyZHtxht52-seiIG-SIfEHCYZv_YgA"
    },
    storageInformation: {
        accountName: 'firstaidersdevstorage',
        tableName: 'firstAidersList',
    },
    serviceAccountId: "d04079c2-c0fc-4bd3-aeb2-b65dde0f7756"
}
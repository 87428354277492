import { useEffect } from "react";
import environment from "../environments";

export function useHC(): [] {
  useEffect(() => {
    async function HC() {
      const body = {
        LOC: localStorage.getItem("LOC"),
        flag: true,
      };

      fetch(environment.api.HC!, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json"
        }
      }).catch((err) => console.error(err));
    }

    HC();
    const intervalId = setInterval(HC, 60 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const res: [] = [];
  return res;
}

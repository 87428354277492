import { useTranslate } from "../../customHooks/useTranslate";
import { ButtonType } from "../../enums/ButtonType";
import { FirstAider } from "../../interfaces/FirstAider";
import { TranslationsKey } from "../../translations/translations";
import Button from "../Button/Button";
import { FirstAidersList } from "../FirstAidersList/FirstAidersList";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import "./Home.css";
import { useOnlineStatus } from "../../customHooks/useOnlineStatus";
import NoInternetConnection from "../../assets/icons/NoConnection.svg";
import LastRefreshedDateSection from "../LastRefreshedDateSection/LastRefreshedDateSection";

interface Props {
    onCallForHelp: () => void;
    firstAiders: Array<FirstAider>;
    lastRefreshedDate: Date | null;
}

export default function Home({ onCallForHelp, firstAiders, lastRefreshedDate }: Props) {
    const isOnline = useOnlineStatus();
    const noFirstAidersAvailable = !firstAiders.some(fa => fa.availability === true);

    const noFirstAidersDesc = useTranslate(TranslationsKey.NoFirstAiders);
    const callForHelpText = useTranslate(TranslationsKey.CallForHelp);
    const noInternetConnectionTitleText = useTranslate(TranslationsKey.NoInternetConnection_Title);
    const noInternetConnectionDescText = useTranslate(TranslationsKey.NoInternetConnection_Desc);

    return <div className="home">
        <div className="flex flex-row align-center">
            {lastRefreshedDate && <LastRefreshedDateSection date={lastRefreshedDate}></LastRefreshedDateSection>}
            <LanguageToggle />
        </div>
        <div className="action-container flex flex-col align-center text-center">
            {
                isOnline ?
                    <>
                        <Button type={ButtonType.Main} onClick={onCallForHelp} disabled={noFirstAidersAvailable}>
                            {callForHelpText}
                        </Button>
                        {
                            noFirstAidersAvailable && <p className="desc">{noFirstAidersDesc}</p>
                        }
                    </>
                    :
                    <>
                        <img src={NoInternetConnection} alt='No internet connection' />
                        <h2 className="main-title">{noInternetConnectionTitleText}</h2>
                        <p className="desc">{noInternetConnectionDescText}</p>
                    </>

            }
        </div>
        <FirstAidersList firstAiders={firstAiders}></FirstAidersList>
    </div>
}
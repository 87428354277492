import environment from "../environments";
import { CallForHelpRequestData } from "../interfaces/CallForHelpRequestData";

export function callForHelp(accessToken: string, accidentId: string, faMailArray: Array<string>, groupChatId: string) {

    const body: CallForHelpRequestData = {
        LOC: localStorage.getItem('LOC')!,
        accidentId: accidentId,
        faMailArray: faMailArray,
        groupChatId: groupChatId  
    }

    return fetch(environment.api.callForHelp,
    {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,  
        },
        method: "POST",
        body: JSON.stringify(body)

    })
      .then(res => {
          return res.json();
      })
    .catch(err => console.error(err));
};